<script lang="ts">
	const year = new Date().getFullYear();
</script>

<!-- Footer -->
<footer id="footer" class="wrapper style1-alt">
	<div class="inner">
		<ul class="menu">
			<li>Copyright &copy; {year} Theoretically Incorporated. All rights reserved.</li>
		</ul>
	</div>
</footer>
