<script lang="ts" context="module">
	declare const jQuery: any;
</script>

<script lang="ts">
	import { onMount } from 'svelte';
	import { initTheme, pageReady } from '$lib/template';

	import Footer from '$lib/components/Footer.svelte';
	import Analytics from '$lib/components/Analytics.svelte';

	onMount(async () => {
		initTheme(jQuery);
		onload();
	});

	// Run this function both on window load and onMount. The function will de-dupe
	// the runs and only run once. In some weird circumstances only one or the other
	// will run or run at the right time.
	function onload() {
		pageReady(jQuery);
	}
</script>

<svelte:window on:load={onload} />

<svelte:head>
	<noscript>
		<style global lang="scss">
			@import '../lib/scss/noscript';
		</style>
	</noscript>
</svelte:head>

<main>
	<Analytics measurementId="G-4VTRM6QBGJ" />

	<slot />

	<Footer />
</main>

<style global lang="scss">
	@import '../lib/scss/main';
</style>
